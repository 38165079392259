import http from "../../utils/http";

// 水表抄表
export const apiGasMeter = {
  // 获取菜单列表
  treeMenu(params) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/tree",
      method: "get",
      params,
    });
  },
  // 分页查询
  pageList(data) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/page",
      method: "post",
      data,
    });
  },
  // 编辑-详情
  detail(params) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/detail",
      method: "get",
      params,
    });
  },
  // 查询期初表显（日）
  gasStart(params) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/get-gas-start",
      method: "get",
      params,
    });
  },
  // 查询期初表显（日）
  deviceList(params) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/meter-devices",
      method: "get",
      params,
    });
  },
  // 编辑/新增
  update(data) {
    return http.request({
      url: "/cemp-energy/gas-meter-reading/save-or-update",
      method: "put",
      data,
    });
  },
  // 下载模板
  downTemplate() {
    return http.request({
      url: `/cemp-file/file/download/meter-import-excel`,
      method: "get",
      responseType: "blob",
    });
  },
  //上传模板
  upload(data) {
    return http.request({
      url: `/cemp-energy/gas-meter-reading/import`,
      method: "post",
      data,
    });
  },
   //导出
   export(data) {
    return http.request({
      url: `/cemp-energy/gas-meter-reading/export`,
      method: "post",
      responseType: "blob",
      data,
    });
  },
};
